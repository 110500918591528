var aqi = 0;
var iaq = 0;


function set_score() {
  jQuery('.iaq-score').html(iaq);
  jQuery('.aqi-score').html(aqi);
}

jQuery('#my-air-form').on("submit", function(e) {
  console.log('Searchign....');

  jQuery('.score-wrapper').addClass('loading');

  var zipcode = jQuery('#zip-code').val();


  axios.get('https://api.zippopotam.us/us/' + zipcode).then(function(response) {

    jQuery('#quality-value').removeClass('error');

    // Reset
    jQuery('#quality-value').css('border-color', '#fff');

    var state = response.data["places"][0]["state abbreviation"];
    var lat = parseFloat(response.data["places"][0].latitude);
    var lng = parseFloat(response.data["places"][0].longitude);


    axios.post("https://api-airnowgov.sonomatech.com/reportingarea/get_state", data = 'state_code=' + state, headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }).then(function(response) {

      var nearest = NearestCity(response.data, lat, lng);

      aqi = nearest.aqi;
      iaq = 5 * aqi;


      set_score();

      jQuery('.quality-summary').addClass('showing');
      jQuery('.quality-summary').addClass('show');

      setTimeout(function() {
        jQuery('.score-wrapper').removeClass('loading');

        // Set color
        if (iaq > 50) iaq_color = '#e0e31b'
        if (iaq > 100) iaq_color = '#f3b712'
        if (iaq > 150) iaq_color = '#f00505'
        if (iaq > 200) iaq_color = '#8d05f0'
        if (iaq > 300) iaq_color = '#840000'

        jQuery('#quality-value').css('border-color', iaq_color);
      }, 1000);





      /*
      var colors = ['#27ba1c', '#27ba1c']
      if (aqi > 50) colors[0] = '#e0e31b'
      if (aqi > 100) colors[0] = '#f3b712'
      if (aqi > 150) colors[0] = '#f00505'
      if (aqi > 200) colors[0] = '#8d05f0'
      if (aqi > 300) colors[0] = '#840000'

      var iaq = 5 * aqi
      if (iaq > 50) colors[1] = '#e0e31b'
      if (iaq > 100) colors[1] = '#f3b712'
      if (iaq > 150) colors[1] = '#f00505'
      if (iaq > 200) colors[1] = '#8d05f0'
      if (iaq > 300) colors[1] = '#840000'
      */

    });




  }).catch(function(error) {
    console.log("error:", error)
    jQuery('#quality-value').html('Zip not found');
    jQuery('#quality-value').addClass('error');
    jQuery('#quality-value').css('border-color', 'transparent');
    jQuery('.score-wrapper').removeClass('loading');
  });


  score = 12345;
  set_score();

  return false;

});



function NearestCity(data, latitude, longitude) {
  var mindif = 99999;
  var recent = new Date("01/01/1970");
  var closest;

  for (index = 0; index < data.length; ++index) {
    var dif = PythagorasEquirectangular(latitude, longitude, data[index]["latitude"], data[index]["longitude"]);
    var date = new Date(data[index]["validDate"]);
    if (data[index]["aqi"]) {
      if (dif <= mindif && recent <= date) {
        closest = index;
        recent = date;
        mindif = dif;
      }
    }
  }

  // echo the nearest
  return data[closest];
}

// Convert Degress to Radians
function Deg2Rad(deg) {
  return deg * Math.PI / 180;
}

function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lon1 = Deg2Rad(lon1);
  lon2 = Deg2Rad(lon2);
  var R = 6371; // km
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = (lat2 - lat1);
  var d = Math.sqrt(x * x + y * y) * R;
  return d;
}